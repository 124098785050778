import {Routes,Route} from "react-router-dom";

import Home from './Home';

function Content() {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
        </Routes>
    );
}

export default Content;
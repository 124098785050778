import {useTranslation} from "react-i18next";

function Home() {
    //Calling t and i18n method from useTranslation hook
    const {t, i18n} = useTranslation();

    //Creating a method to change the language onChnage from select box
    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value
        i18n.changeLanguage(languageValue);
    }
    return (
        <div>

            <section className="main-slider">
                <div className="swiper-container thm-swiper__slider" data-swiper-options='{
        "slidesPerView": 1,
        "loop": true,
        "effect": "fade",
        "autoplay": {
            "delay": 5000
        },
        "navigation": {
            "nextEl": "#main-slider__swiper-button-next",
            "prevEl": "#main-slider__swiper-button-prev"
        }
    }'>
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="image-layer"
                                 style={{backgroundImage: "url(assets/images/main-slider/main-slider-1-1.jpg)"}}>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7">
                                        <span className="tagline">Welcome to Agriculture Farm</span>
                                        <h2><span>Agriculture</span> <br/>
                                            & Eco Farming</h2>
                                        <p>There are many of passages of lorem Ipsum, but the majori have <br/> suffered
                                            alteration
                                            in some form.</p>
                                        <a href="#" className=" thm-btn">Discover More</a>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="swiper-slide">
                            <div className="image-layer"
                                 style={{backgroundImage: "url(assets/images/main-slider/main-slider-1-2.jpg)"}}>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7">
                                        <span className="tagline">Welcome to Agriculture Farm</span>
                                        <h2><span>Agriculture</span><br/>
                                            & Eco Farming</h2>
                                        <p>There are many of passages of lorem Ipsum, but the majori have <br/> suffered
                                            alteration
                                            in some form.</p>
                                        <a href="#" className=" thm-btn">Discover More</a>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="main-slider__nav">
                        <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i
                            className="agrikon-icon-left-arrow"></i>
                        </div>
                        <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i
                            className="agrikon-icon-right-arrow"></i></div>
                    </div>


                </div>

            </section>


            <section className="service-one">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                            <div className="service-one__box">
                                <img src="assets/images/services/service-1-1.jpg" alt=""/>
                                <div className="service-one__box-content">
                                    <h3><a href="service-details.html">Agriculture Leader</a></h3>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-12 col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                            <div className="service-one__box">
                                <img src="assets/images/services/service-1-2.jpg" alt=""/>
                                <div className="service-one__box-content">
                                    <h3><a href="service-details.html">Quality Standards</a></h3>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-12 col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
                            <div className="service-one__box">
                                <img src="assets/images/services/service-1-3.jpg" alt=""/>
                                <div className="service-one__box-content">
                                    <h3><a href="service-details.html">Organic Services</a></h3>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>


            <section className="about-one">
                <img src="assets/images/icons/about-bg-icon-1-1.png" className="about-one__bg-shape-1" alt=""/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-one__images">
                                <img src="assets/images/resources/about-1-1.jpg" alt=""/>
                                <img src="assets/images/resources/about-1-2.jpg" alt=""/>
                                <div className="about-one__count wow fadeInLeft" data-wow-duration="1500ms">
                                    <span>Trusted by</span>
                                    <h4>8900</h4>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-7">
                            <div className="about-one__content">
                                <div className="block-title text-left">
                                    <div className="block-title__image"></div>

                                    <p>Welcome to agricon</p>
                                    <h3>Better Agriculture for
                                        Better Future</h3>
                                </div>

                                <div className="about-one__tagline">
                                    <p>We have 30 years of agriculture & eco farming experience globaly</p>
                                </div>

                                <div className="about-one__summery">
                                    <p>There are many variations of passages of lorem ipsum available but the
                                        majority have suffered
                                        alteration in some form by injected humor or random word which don't look
                                        even.</p>
                                </div>

                                <div className="about-one__icon-row">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="about-one__box">
                                                <i className="agrikon-icon-farmer"></i>
                                                <h4><a href="about.html">Professional
                                                    Farmers</a></h4>
                                            </div>

                                        </div>

                                        <div className="col-lg-6">
                                            <div className="about-one__box">
                                                <i className="agrikon-icon-agriculture"></i>
                                                <h4><a href="services.html">Organic & Eco
                                                    Solutions</a></h4>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <a href="about.html" className="thm-btn">Discover More</a>
                            </div>

                        </div>

                    </div>

                </div>
            </section>


            <section className="service-two">
                <div className="service-two__bottom-curv"></div>

                <div className="container">
                    <div className="block-title text-center">
                        <div className="block-title__image"></div>

                        <p>Our Services list</p>
                        <h3>What We’re Offering</h3>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="service-two__card">
                                <div className="service-two__card-image">
                                    <img src="assets/images/services/service-2-1.jpg" alt=""/>
                                </div>

                                <div className="service-two__card-content">
                                    <div className="service-two__card-icon">
                                        <i className="agrikon-icon-tractor"></i>
                                    </div>

                                    <h3><a href="service-details.html">Agriculture Products</a></h3>
                                    <p>Lorem ium dolor sit ametad pisicing elit sed simply do ut.</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="service-two__card">
                                <div className="service-two__card-image">
                                    <img src="assets/images/services/service-2-2.jpg" alt=""/>
                                </div>

                                <div className="service-two__card-content">
                                    <div className="service-two__card-icon">
                                        <i className="agrikon-icon-organic-food"></i>
                                    </div>

                                    <h3><a href="service-details.html">Oragnic
                                        Products</a></h3>
                                    <p>Lorem ium dolor sit ametad pisicing elit sed simply do ut.</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="service-two__card">
                                <div className="service-two__card-image">
                                    <img src="assets/images/services/service-2-3.jpg" alt=""/>
                                </div>

                                <div className="service-two__card-content">
                                    <div className="service-two__card-icon">
                                        <i className="agrikon-icon-vegetable"></i>
                                    </div>

                                    <h3><a href="service-details.html">Fresh
                                        Vegetables</a></h3>
                                    <p>Lorem ium dolor sit ametad pisicing elit sed simply do ut.</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="service-two__card">
                                <div className="service-two__card-image">
                                    <img src="assets/images/services/service-2-4.jpg" alt=""/>
                                </div>

                                <div className="service-two__card-content">
                                    <div className="service-two__card-icon">
                                        <i className="agrikon-icon-dairy"></i>
                                    </div>

                                    <h3><a href="service-details.html">Dairy
                                        Products</a></h3>
                                    <p>Lorem ium dolor sit ametad pisicing elit sed simply do ut.</p>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>


            <div className="projects-one project-one__home-one">
                <div className="container">
                    <div className="block-title text-center">
                        <div className="block-title__image"></div>

                        <p>Closed projects</p>
                        <h3>Latest Projects List</h3>
                    </div>

                    <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 0, "slidesPerView": 1, "loop": true, "slidesPerGroup": 1, "pagination": {
            "el": "#projects-one__swiper-pagination",
            "type": "bullets",
            "clickable": true
        },
        "breakpoints": {
            "0": {
                "spaceBetween": 0,
                "slidesPerView": 1,
                "slidesPerGroup": 1
            },
            "640": {
                "spaceBetween": 30,
                "slidesPerView": 2,
                "slidesPerGroup": 2
            },
            "992": {
                "spaceBetween": 30,
                "slidesPerView": 2,
                "slidesPerGroup": 2
            }
        }}'>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="projects-one__single">
                                    <img src="assets/images/projects/project-2-1.jpg" alt=""/>
                                    <div className="projects-one__content">
                                        <h3>Harvest Innovation</h3>
                                        <a href="project-details.html" className="projects-one__button"><i
                                            className="agrikon-icon-right-arrow"></i></a>
                                    </div>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="projects-one__single">
                                    <img src="assets/images/projects/project-2-2.jpg" alt=""/>
                                    <div className="projects-one__content">
                                        <h3>Harvest Innovation</h3>
                                        <a href="project-details.html" className="projects-one__button"><i
                                            className="agrikon-icon-right-arrow"></i></a>
                                    </div>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="projects-one__single">
                                    <img src="assets/images/projects/project-2-3.jpg" alt=""/>
                                    <div className="projects-one__content">
                                        <h3>Harvest Innovation</h3>
                                        <a href="project-details.html" className="projects-one__button"><i
                                            className="agrikon-icon-right-arrow"></i></a>
                                    </div>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="projects-one__single">
                                    <img src="assets/images/projects/project-2-4.jpg" alt=""/>
                                    <div className="projects-one__content">
                                        <h3>Harvest Innovation</h3>
                                        <a href="project-details.html" className="projects-one__button"><i
                                            className="agrikon-icon-right-arrow"></i></a>
                                    </div>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="projects-one__single">
                                    <img src="assets/images/projects/project-2-2.jpg" alt=""/>
                                    <div className="projects-one__content">
                                        <h3>Harvest Innovation</h3>
                                        <a href="project-details.html" className="projects-one__button"><i
                                            className="agrikon-icon-right-arrow"></i></a>
                                    </div>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="projects-one__single">
                                    <img src="assets/images/projects/project-2-4.jpg" alt=""/>
                                    <div className="projects-one__content">
                                        <h3>Harvest Innovation</h3>
                                        <a href="project-details.html" className="projects-one__button"><i
                                            className="agrikon-icon-right-arrow"></i></a>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="swiper-pagination" id="projects-one__swiper-pagination"></div>
                    </div>

                </div>

            </div>


            <section className="call-to-action__three jarallax" data-jarallax data-speed="0.3"
                     data-img-position="50% 50%">
                <img className="call-to-action__three__bg jarallax-img" src="assets/images/backgrounds/cta-1-bg-1.jpg"
                     alt="parallax-image"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 wow fadeInLeft" data-wow-duration="1500ms">
                            <div className="call-to-action__three-image">
                                <img src="assets/images/resources/cta-3-1.jpg" alt=""/>
                                <img src="assets/images/resources/cta-3-2.jpg" alt=""/>
                            </div>

                        </div>

                        <div className="col-lg-7">
                            <div className="call-to-action__three-content">
                                <h3>Providing High Quality
                                    Products</h3>
                                <a href="about.html" className="thm-btn">Discover More</a>
                            </div>

                        </div>

                    </div>

                </div>

            </section>


            <section className="testimonials-one">
                <img src="assets/images/icons/testimonials-bg-1-1.png" className="testimonials-one__bg" alt=""/>
                <div className="container">
                    <h2 className="testimonials-one__title">Testimonials</h2>
                    <div id="testimonials-one__carousel" className="testimonials-one__carousel swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="testimonials-one__icons">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>

                                <p>This is due to their excellent service, competitive pricing and customer support.
                                    It’s throughly
                                    refresing to get such a personal touch. Duis aute lorem ipsum is simply free
                                    text irure dolor in
                                    reprehenderit in esse nulla pariatur.</p>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonials-one__icons">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>

                                <p>This is due to their excellent service, competitive pricing and customer support.
                                    It’s throughly
                                    refresing to get such a personal touch. Duis aute lorem ipsum is simply free
                                    text irure dolor in
                                    reprehenderit in esse nulla pariatur.</p>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonials-one__icons">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>

                                <p>This is due to their excellent service, competitive pricing and customer support.
                                    It’s throughly
                                    refresing to get such a personal touch. Duis aute lorem ipsum is simply free
                                    text irure dolor in
                                    reprehenderit in esse nulla pariatur.</p>
                            </div>

                        </div>

                    </div>


                    <div id="testimonials-one__thumb" className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="testimonials-one__image">
                                    <img src="assets/images/resources/testimonials-1-1.jpg" alt=""/>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="testimonials-one__image">
                                    <img src="assets/images/resources/testimonials-1-2.jpg" alt=""/>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="testimonials-one__image">
                                    <img src="assets/images/resources/testimonials-1-3.jpg" alt=""/>
                                </div>

                            </div>

                        </div>

                    </div>


                    <div id="testimonials-one__meta" className="testimonials-one__carousel swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="testimonials-one__meta">
                                    <h4>Jessica Brown</h4>
                                    <span>Customer</span>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="testimonials-one__meta">
                                    <h4>Caleb Hoffman</h4>
                                    <span>Customer</span>
                                </div>

                            </div>

                            <div className="swiper-slide">
                                <div className="testimonials-one__meta">
                                    <h4>Bradley Kim</h4>
                                    <span>Customer</span>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="swiper-pagination" id="testimonials-one__swiper-pagination"></div>
                </div>
            </section>


            <section className="gray-boxed-wrapper home-one__boxed">
                <img src="assets/images/icons/home-1-blog-bg.png" alt="" className="home-one__boxed-bg"/>
                    <section className="blog-home-two blog-home-one">
                        <div className="container">
                            <div className="row top-row">
                                <div className="col-lg-6">
                                    <div className="block-title">
                                        <div className="block-title__image"></div>

                                        <p>From the blog post</p>
                                        <h3>Latest News & Articles
                                            Directly from Blog</h3>
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    <p className="block-text">Lorem ipsum is simply free text available. Aenean eu leo
                                        quam. Pellentesque
                                        ornare
                                        sem lacinia
                                        quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur.</p>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12 col-lg-4">
                                    <div className="blog-card">
                                        <div className="blog-card__image">
                                            <img src="assets/images/blog/blog-grid-1.jpg"
                                                 alt="Best Way to Do Eco and Agriculture"/>
                                                <a href="blog-details.html"></a>
                                        </div>

                                        <div className="blog-card__content">
                                            <div className="blog-card__date">18 Nov</div>

                                            <div className="blog-card__meta">
                                                <a href="blog-details.html"><i className="far fa-user-circle"></i> by
                                                    Admin</a>
                                                <a href="blog-details.html"><i className="far fa-comments"></i> 2
                                                    Comments</a>
                                            </div>

                                            <h3><a href="blog-details.html">Best Way to Do Eco and Agriculture</a></h3>
                                            <a href="blog-details.html" className="thm-btn">Read More</a>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-md-12 col-lg-4">
                                    <div className="blog-card">
                                        <div className="blog-card__image">
                                            <img src="assets/images/blog/blog-grid-2.jpg"
                                                 alt="Leverage agile frameworks to provide"/>
                                            <a href="blog-details.html"></a>
                                        </div>

                                        <div className="blog-card__content">
                                            <div className="blog-card__date">18 Nov</div>

                                            <div className="blog-card__meta">
                                                <a href="blog-details.html"><i className="far fa-user-circle"></i> by
                                                    Admin</a>
                                                <a href="blog-details.html"><i className="far fa-comments"></i> 2
                                                    Comments</a>
                                            </div>

                                            <h3><a href="blog-details.html">Leverage agile frameworks to provide</a>
                                            </h3>
                                            <a href="blog-details.html" className="thm-btn">Read More</a>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-md-12 col-lg-4">
                                    <div className="blog-card">
                                        <div className="blog-card__image">
                                            <img src="assets/images/blog/blog-grid-3.jpg"
                                                 alt="Organically grow the holistic world view"/>
                                            <a href="blog-details.html"></a>
                                        </div>

                                        <div className="blog-card__content">
                                            <div className="blog-card__date">18 Nov</div>

                                            <div className="blog-card__meta">
                                                <a href="blog-details.html"><i className="far fa-user-circle"></i> by
                                                    Admin</a>
                                                <a href="blog-details.html"><i className="far fa-comments"></i> 2
                                                    Comments</a>
                                            </div>

                                            <h3><a href="blog-details.html">Organically grow the holistic world view</a>
                                            </h3>
                                            <a href="blog-details.html" className="thm-btn">Read More</a>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <hr/>
                        </div>

                    </section>

                    <div className="blog-home__slogan">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="blog-home__slogan-main">
                                        <i className="agrikon-icon-farm"></i>
                                        <div className="blog-home__slogan-content">
                                            <h3>We Care About Our Agriculture Growth</h3>
                                            <p>There are many variations of passages of Lorem Ipsum available, but the
                                                majority have
                                                suffered in some form, by injected humour words.</p>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-lg-3">
                                    <div className="blog-home__slogan-image">
                                        <img src="assets/images/resources/blog-cta-1.png" alt=""/>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
            </section>


            <section className="contact-two">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5">
                            <div className="contact-two__image">
                                <div className="contact-two__image-bubble-1"></div>

                                <div className="contact-two__image-bubble-2"></div>

                                <div className="contact-two__image-bubble-3"></div>

                                <img src="assets/images/resources/contact-1-1.jpg" className="img-fluid" alt=""/>
                            </div>

                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                            <div className="contact-two__content">
                                <div className="block-title">
                                    <div className="block-title__image"></div>

                                    <p>Contact now</p>
                                    <h3>Leave Us A
                                        Message</h3>
                                </div>

                                <div className="contact-two__summery">
                                    <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod
                                        tempor
                                        incididunt
                                        ut labore et dolore magna aliqua lonm andhn. Aenean tincidunt id mauris id
                                        auctor. Donec at
                                        ligula lacus dignissim mi quis simply neque.</p>
                                </div>

                            </div>

                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                            <form action="assets/inc/sendemail.php"
                                  className="contact-one__form contact-form-validated">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <input type="text" name="name" placeholder="Full Name"/>
                                    </div>

                                    <div className="col-lg-12">
                                        <input type="text" name="email" placeholder="Email Address"/>
                                    </div>

                                    <div className="col-lg-12">
                                        <input type="text" name="phone" placeholder="Phone Number"/>
                                    </div>

                                    <div className="col-lg-12">
                                        <textarea name="message" placeholder="Write Message"></textarea>
                                    </div>

                                    <div className="col-lg-12">
                                        <button type="submit" className="thm-btn">Send Message</button>

                                    </div>

                                </div>

                            </form>
                        </div>

                    </div>

                </div>

            </section>


            <div className="client-carousel client-carousel__has-border-top">
                <div className="container">
                    <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 140, "slidesPerView": 5, "autoplay": { "delay": 5000 }, "breakpoints": {
                "0": {
                    "spaceBetween": 30,
                    "slidesPerView": 2
                },
                "375": {
                    "spaceBetween": 30,
                    "slidesPerView": 2
                },
                "575": {
                    "spaceBetween": 30,
                    "slidesPerView": 3
                },
                "767": {
                    "spaceBetween": 50,
                    "slidesPerView": 4
                },
                "991": {
                    "spaceBetween": 50,
                    "slidesPerView": 5
                },
                "1199": {
                    "spaceBetween": 100,
                    "slidesPerView": 5
                }
            }}'>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                            <div className="swiper-slide">
                                <img src="assets/images/resources/brand-1-1.png" alt=""/>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
);
}

export default Home;